import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCZpqT2hwVVntPOh05edB6U7oCmUiNcAuY",
  authDomain: "mahfuz-and-irene.firebaseapp.com",
  projectId: "mahfuz-and-irene",
  storageBucket: "mahfuz-and-irene.appspot.com",
  messagingSenderId: "915537791580",
  appId: "1:915537791580:web:c326c46c1a16b6d8b07499",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
