import "./Home.css";
import { auth } from "../firebase-config";
import {signOut} from "firebase/auth";
import headerBackgroundImage from '../assets/header-bg-img.jpg';
import headerImage from '../assets/header-img.jpg';
import locationsImage from '../assets/locations.png';
import playlistCover from '../assets/playlist-image.png';
import playlistLayerOne from '../assets/playlist-songs-1.png';
import playlistLayerTwo from '../assets/playlist-songs-2.png';
import section3Images from '../assets/section-3-part-b.png';
import {ReactComponent as VennDiagram} from '../assets/venn-diagram.svg';
import seaFrontImage from '../assets/sea-front.png';
import weddingImage from '../assets/wedding-image.jpg';
import meal1Image from '../assets/meal-1.png';
import meal2Image from '../assets/meal-2.png';
import meal3Image from '../assets/meal-3.png';
import meal4Image from '../assets/meal-4.png';
import meal5Image from '../assets/meal-5.png';
import meal6Image from '../assets/meal-6.png';
import meal7Image from '../assets/meal-7.png';
import meal8Image from '../assets/meal-8.png';

const Home = () => {

  const logout = async () => {
    await signOut(auth);
  };

  return (
    <div>

      <section className="section-one" style={{ backgroundImage: `url("${headerBackgroundImage}")` }}>
      {/*<section className="section-one">*/}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <img src={headerImage} className="header-image" alt="wedding" />
              <h1 title="A Year With You" className="text-center glitch-effect">A Year With You</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="section-two">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <h2 className="glitch-effect">Where it began...</h2>
                    <img src={locationsImage} className="locations" alt="locations" />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <h3>30.01.2021</h3>
                    <p>It was a cold January evening when we first talked. We'd only had a brief message conversation until then so I had no idea who to expect.</p>
                    <p>I was so nervous and anxious. What if I didn't get along with you? What if you didn't like me and I liked you? What if I mess up and say the wrong thing?</p>
                    <p>And then suddenly there you were. Those eyes. You have the loveliest eyes — sweet, kind and gentle — with this depth behind them that conveys such genuineness. I was mesmerised. You made me feel so warm and comfortable from the way you talked with me. All that anxiety from before started to fade away and in that moment it felt like we were the only two people in the world.</p>
                    <p>It was like finally finding the missing piece of a puzzle. Something just...fit.</p>
                    <p>Then came the strangest thing — I could actually imagine a future, but a different kind, one where the two of us grow together.</p>
                    <p>Who knew this would only be the start of one of many virtual rendezvous?</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-four">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <VennDiagram></VennDiagram>
            </div>
          </div>
        </div>
      </section>

      <section className="section-five">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h2 className="glitch-effect">Better in Person</h2>
              <p>When we first met in Belfast, I just couldn't get over how radiant you looked in real life. Not the usual grainy quality of video calls, none of the lags and freezes. Cameras really don't do you justice.</p>
              <p>And then that smile. You would smile and I felt like I could just melt on the spot. I still feel like that. I could barely even look at you. I knew once I did, I would never be able to look away.</p>
              <p>We barely got to chat, but those quiet moments of us sitting in the garden, sipping on tea, or walking side by side along the sea front on a cloudy evening — those moments were magical.</p>
            </div>
            <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
              <img src={seaFrontImage} className="sea-front" alt="sea front" />
              <h3>05.06.2021</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="section-six">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center align-items-center">
                <h2 className="glitch-effect">Always &<br></br>Forever</h2>
                <img src={weddingImage} className="wedding-image" alt="wedding" />
                <h3>23.10.2021</h3>
                <p>I finally get to say I'm yours.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-three">
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            <div className="col-sm-12 col-md-6 text-center part-a">
              <div className="row">
                <div className="col-12">
                  <h3>Our Playlist</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <img src={playlistLayerOne} className="playlist-songs-one" alt="playlist songs" />
                  <img src={playlistLayerTwo} className="playlist-songs-two" alt="playlist songs" />
                  <a href="https://open.spotify.com/playlist/0FTq9ltRB9k3fTidLsn6Rx?si=6fda617138c1459f&pt=78953f204c090170e671fc71bb8111f8">
                    <img src={playlistCover} className="playlist-cover" alt="playlist cover" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 part-b">
              <p>How can I even begin to explain how I felt when we finally married? Finally. We had worked so hard for this. It was a long time coming.</p>
              <p>That first night when you held my hand in the car, it felt like electricity coursing through and a thousand butterflies in my stomach. I was dizzy with the disbelief that I could finally be close to you.</p>
              <p>I felt so safe with you. I still do.</p>
              <p>That entire week was a dream. Laughing, joking, eating a lot, learning more about each other, lying in your arms. Honestly, no other place I would rather be than with you.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-seven">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center align-items-center">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img src={meal1Image} className="meals" alt="meal" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img src={meal2Image} className="meals" alt="meal" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img src={meal3Image} className="meals" alt="meal" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img src={meal4Image} className="meals" alt="meal" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img src={meal5Image} className="meals" alt="meal" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img src={meal6Image} className="meals" alt="meal" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img src={meal7Image} className="meals" alt="meal" />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img src={meal8Image} className="meals" alt="meal" />
                  </div>
                </div>
                <h2>Doing what we love most…</h2>
                <h3>…eating.</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-eight">
        <div className="container">
          <div className="row">
            <div className="col-12">
                <h2>The Best is Yet to Come</h2>
                <p>What I've come to realise is that there's no one else I'd rather get into duvet wars with in the middle of the night or have snoring loud af beside me.</p>
                <p>We have our moments — from silly conversations to ranting about gender equality, from cheesy dad jokes to tender kisses. And there's no one else I'd rather go on this journey with than you.</p>
                <p>I love you and I can't wait to spend many more years with you.</p>
            </div>
          </div>
        </div>
      </section>

      <footer className="d-flex justify-content-end">
        <button onClick={logout}>Sign Out</button>
      </footer>

    </div>
  );
}

export default Home;